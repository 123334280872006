import React, { useState } from "react"
// Component
import ProjectHero from "../../section/projectpage/projectpage-hero"
import { BlockFullImage, BlockWideImage } from "../../components/project/image"
import Divider from "../../components/divider"
import ProjectAbout from "../../section/projectpage/projectpage-about"
import {
  OneColumnHeading,
  OneColumnTitle,
  OneColumnElementTitle,
} from "../../components/project/one-column"
import {
  TwoColumnHeading,
  TwoColumnTitle,
} from "../../components/project/two-column"
import Quote from "../../components/project/quote"
import { Display, DisplaySizePlus } from "../../components/project/display"
import ProjectNext from "../../section/projectpage/projectpage-next"
// Layout
import LayoutWrapper from "../../layouts/wrapper"
import ScrollWrapper from "../../layouts/scroll-wrapper"
import Metadata from "../../layouts/metadata"
import Header from "../../layouts/header"
import { Container, ProjectContainer } from "../../layouts/container"
import Footer from "../../layouts/footer"
import Social from "../../layouts/social"
import Video from "../../components/project/video"

// Data
import useProjectData from "../../hook/projectpage-data"
import useRutenMessengerData from "../../hook/projects/ruten-messenger-data"
import assetsPrototype1 from "../../assets/video/ruten-messenger/prototype-1.mp4"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"

export default function Template() {
  // MENU: Data
  const {
    about_content,
    about_title,
    hero_image,
    project_description,
    project_name,
    role,
    timeline,
    tool,
    type,
    order1,
    body,
    body1,
  } = useRutenMessengerData()

  const ProjectHeroData = {
    num: `0${Number(order1.text)}`,
    image_url: hero_image.fluid,
    name: project_name.raw,
    description: project_description.text,
  }
  const ProjectAboutData = {
    subtitle: "Overview",
    title: about_title.text,
    content: about_content.text,
    timeline: timeline.text,
    type: type.text,
    role: role.text,
    tool: tool.text,
  }
  const SliceData = body
  const SliceData2 = body1

  const RoleData = {
    num: SliceData[0].primary.num.text,
    heading: SliceData[0].primary.heading.text,
    content: SliceData[0].items[0].content.text,
  }

  const ProblemsData = {
    num: SliceData[1].primary.num.text,
    heading: SliceData[1].primary.heading.text,
    content: SliceData[1].items[0].content.text,
    display: SliceData[2].primary.quote.text,
    content2: SliceData[1].items[1].content.text,
    quote: SliceData[3].primary.quote.text,
    content3: SliceData[1].items[2].content.text,
    content4: SliceData[1].items[3].content.text,
  }

  const OpportunityData = {
    num: SliceData[4].primary.num.text,
    heading: SliceData[4].primary.heading.text,
    content: SliceData[4].items[0].content.text,
    display: SliceData[4].items[1].title.text,
    display2: SliceData[4].items[2].title.text,
  }

  const IterationsData = {
    num: SliceData2[0].primary.num.text,
    heading: SliceData2[0].primary.heading.text,
    content: SliceData2[0].items[0].content.text,
    image_url: SliceData2[0].primary.image.fluid,
    image_alt: SliceData2[0].primary.image.alt,
  }

  // MENU: Design Detail Data
  const DesignLabelData = {
    num: SliceData2[1].primary.num.text,
    heading: SliceData2[1].primary.heading.text,
    title: SliceData2[1].items[0].title.text,
    content: SliceData2[1].items[0].content.text,
    image_url: SliceData2[2].items[0].image.fluid,
    image_alt: SliceData2[2].items[0].image.alt,
    // image_url2: SliceData2[2].items[1].image.fluid,
    // image_alt2: SliceData2[2].items[1].image.alt,
  }

  const DesignEssentialData = {
    title: SliceData2[3].items[0].title.text,
    content: SliceData2[3].items[0].content.text,
    image_url: SliceData2[4].items[0].image.fluid,
    image_alt: SliceData2[4].items[0].image.alt,
    image_url2: SliceData2[4].items[1].image.fluid,
    image_alt2: SliceData2[4].items[1].image.alt,
  }
  const DesignDirectionData = {
    title: SliceData2[5].items[0].title.text,
    content: SliceData2[5].items[0].content.text,
    display: SliceData2[6].primary.quote.text,
    content2: SliceData2[5].items[1].content.text,
    image_url: SliceData2[7].items[0].full_image.fluid,
    image_alt: SliceData2[7].items[0].full_image.alt,
    image_url2: SliceData2[7].items[1].full_image.fluid,
    image_alt2: SliceData2[7].items[1].full_image.alt,
  }

  const DesignRedefinedData = {
    title: SliceData2[8].items[0].title.text,
    content: SliceData2[8].items[0].content.text,
    content2: SliceData2[8].items[1].content.text,
    image_url: SliceData2[9].items[0].image.fluid,
    image_alt: SliceData2[9].items[0].image.alt,
  }

  const DesignSystemData = {
    title: SliceData2[10].items[0].title.text,
    content: SliceData2[10].items[0].content.text,
    image_url: SliceData2[11].items[0].full_image.fluid,
    image_alt: SliceData2[11].items[0].full_image.alt,
    image_url2: SliceData2[11].items[1].full_image.fluid,
    image_alt2: SliceData2[11].items[1].full_image.alt,
    image_url3: SliceData2[12].items[0].full_image.fluid,
    image_alt3: SliceData2[12].items[0].full_image.alt,
  }

  const DesignMoreData = {
    title: SliceData2[13].items[0].title.text,
    content: SliceData2[13].items[0].content.text,
    image_url: SliceData2[13].primary.image.fluid,
    image_alt: SliceData2[13].primary.image.alt,
  }

  const LearnData = {
    num: SliceData2[14].primary.num.text,
    heading: SliceData2[14].primary.heading.text,
    title: SliceData2[14].items[0].title.text,
    content: SliceData2[14].items[0].content.text,
    content2: SliceData2[14].items[1].content.text,
    content3: SliceData2[14].items[2].content.text,
  }

  return pug`
    Metadata(title=project_name.text)
    Header
    Social
    main
      ProjectHero(num=ProjectHeroData.num image_url=ProjectHeroData.image_url name=ProjectHeroData.name description=ProjectHeroData.description)
      Container.flex.justify-center.items-center.flex-col
        // About
        ProjectAbout(subtitle=ProjectAboutData.subtitle title=ProjectAboutData.title content=ProjectAboutData.content timeline=ProjectAboutData.timeline type=ProjectAboutData.type role=ProjectAboutData.role tool=ProjectAboutData.tool)

        ProjectContainer
          // Role
          OneColumnHeading(num=RoleData.num heading=RoleData.heading content=RoleData.content)

          // Problems
          OneColumnHeading(num=ProblemsData.num heading=ProblemsData.heading content=ProblemsData.content)
            Display(className=oneColumnStyle.content style="txt-under-highlight-secondary" mark=ProblemsData.display)
            OneColumnElementTitle(content=ProblemsData.content2)
          //- 為了排版而新增的空白項目
          OneColumnTitle()
            Quote(content=ProblemsData.quote)
            OneColumnElementTitle(content=ProblemsData.content3)
          OneColumnTitle(content=ProblemsData.content4)

          // Opportunity
          OneColumnHeading(num=OpportunityData.num heading=OpportunityData.heading content=OpportunityData.content)
            DisplaySizePlus.mt-4(mark=OpportunityData.display style="txt-full-highlight-dark-secondary-light1")
            DisplaySizePlus(mark=OpportunityData.display2 style="txt-full-highlight-dark-primary-light2")

          // Design Iterations
          TwoColumnHeading(num=IterationsData.num heading=IterationsData.heading content=IterationsData.content image_url=IterationsData.image_url image_alt=IterationsData.image_alt)

          //Design Detail
          //-Label your messages
          OneColumnHeading(num=DesignLabelData.num heading=DesignLabelData.heading )
            OneColumnElementTitle.my-8(title=DesignLabelData.title content=DesignLabelData.content)
            // video(autoplay="autoplay" loop muted playsinline="playsinline")
            //   source(src=assetsPrototype1 type="video/mp4")
            Video(src=assetsPrototype1 alt="Label message prototype")
          BlockFullImage(image_url=DesignLabelData.image_url image_alt=DesignLabelData.image_alt)
          // BlockWideImage(image_url=DesignLabelData.image_url2 image_alt=DesignLabelData.image_alt2)

          //- All about essential detail
          OneColumnHeading(title=DesignEssentialData.title content=DesignEssentialData.content)
          BlockWideImage(image_url=DesignEssentialData.image_url image_alt=DesignEssentialData.image_alt)
          BlockWideImage(image_url=DesignEssentialData.image_url2 image_alt=DesignEssentialData.image_alt2)

          //- Give Right Direction to Users
          OneColumnHeading(title=DesignDirectionData.title content=DesignDirectionData.content)
            Display(className=oneColumnStyle.content mark=DesignDirectionData.display style="txt-under-highlight-secondary")
          BlockWideImage(image_url=DesignDirectionData.image_url image_alt=DesignDirectionData.image_alt)
          OneColumnTitle.mt-16(content=DesignDirectionData.content2)
          BlockWideImage(image_url=DesignDirectionData.image_url2 image_alt=DesignDirectionData.image_alt2)

          //-Redefined our new top bar
          OneColumnHeading(title=DesignRedefinedData.title content=DesignRedefinedData.content)
          BlockWideImage(image_url=DesignRedefinedData.image_url image_alt=DesignRedefinedData.image_alt)

          //- Design System for Ruten Messenger
          OneColumnHeading(title=DesignSystemData.title content=DesignSystemData.content)
          BlockWideImage(image_url=DesignSystemData.image_url image_alt=DesignSystemData.image_alt)
          BlockWideImage(image_url=DesignSystemData.image_url2 image_alt=DesignSystemData.image_alt2)
          BlockFullImage(image_url=DesignSystemData.image_url3 image_alt=DesignSystemData.image_alt3)

          //- More personality
          TwoColumnHeading(title=DesignMoreData.title content=DesignMoreData.content image_url=DesignMoreData.image_url image_alt=DesignMoreData.image_alt)

          //What did I learn
          OneColumnHeading(num=LearnData.num heading=LearnData.heading title=LearnData.title content=LearnData.content)
            OneColumnElementTitle.my-8(content=LearnData.content2)
            OneColumnElementTitle(content=LearnData.content3)

      ProjectNext(index=order1.text)

  `
}
